import React, { useContext } from 'react';
import { Button, Container, Nav, NavLink, Navbar } from 'react-bootstrap';
import { modalContext } from '../context';
const Header = () => {
    const { openModal } = useContext(modalContext)

    return (
        <>
            <Navbar className="bg-white" expand='lg' id='#'>
                <Container>
                    <Navbar.Brand href="#home">
                        <img src={require('../images/logo.png')} width={150} />
                    </Navbar.Brand>
                    <div className="d-none align-items-center d-lg-flex gap-4">
                        <a className="d-flex  align-items-center text gap-3" href='tel:919344352529'>
                            <img src={require('../images/call.png')} width={45} />
                            <div>
                                <div className='fw-bold'><u>Contact us</u></div>
                                <div className='fw-light'>+91-93443 52529</div>
                            </div>
                        </a>
                        <a className="d-flex  align-items-center text gap-3" href='mailto:admiullas1897@gmail.com'>
                            <img src={require('../images/call.png')} width={45} />
                            <div>
                                <div className='fw-bold'><u>Email us</u></div>
                                <div className='fw-light'>admiullas1897@gmail.com</div>
                            </div>
                        </a>
                    </div>
                </Container>
            </Navbar>
            <Navbar className="bg-primary" expand='lg' variant='dark'>
                <Container>
                    {
                        window.innerWidth < 992 ?
                            <div className='d-flex text-lg-start text-center justify-content-between w-100 align-items-center'>
                                <div className="lead fw-bold text-white">MENU</div>
                                <Navbar.Toggle />
                            </div>
                            : null
                    }

                    <Navbar.Collapse id="basic-navbar-nav">
                        <div className='d-lg-flex text-lg-start text-center justify-content-between w-100 align-items-center'>
                            <Nav>
                                <NavLink href="#" className='me-md-4' >Home</NavLink>
                                <NavLink href="#about" className='me-md-4' >About</NavLink>
                                <NavLink href="#course" className='me-md-4' >Course</NavLink>
                                <NavLink href="#contact">Contact us</NavLink>
                            </Nav>
                            <Button variant='light' onClick={openModal}> For Admissions</Button>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header
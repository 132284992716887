import React from 'react';
import { Container } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

const Banner = () => {
    return (
        <div>
            <Carousel interval={3000}>
                <Carousel.Item>
                    <img src={require('../images/hero-banner.jpg')} className='w-100' />
                </Carousel.Item>
                <Carousel.Item>
                    <img src={require('../images/hero-banner-02.png')} className='w-100' />
                </Carousel.Item>
                <Carousel.Item>
                    <img src={require('../images/hero-banner-03.png')} className='w-100' />
                </Carousel.Item>
            </Carousel>
            <div className="bg-primary text-white">
                <Container>
                    <div className="row justify-content-between text-center">
                        <div className="col-md-3 col-6 p-4">
                            <div className="lead mb-1 fs-3 fw-bold">1,072+</div>
                            <div>Teachers</div>
                        </div>
                        <div className="col-md-3 col-6 p-4">
                            <div className="lead mb-1 fs-3 fw-bold">16,642+</div>
                            <div>Students</div>
                        </div>
                        <div className="col-md-3 col-6 p-4">
                            <div className="lead mb-1 fs-3 fw-bold">2,458+</div>
                            <div>Research</div>
                        </div>
                        <div className="col-md-3 col-6 p-4">
                            <div className="lead mb-1 fs-3 fw-bold">44+</div>
                            <div>Awards</div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Banner
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const About = () => {
    return (
        <Container className='py-md-5 my-md-5 py-3' id='about'>
            <Row className='align-items-center'>
                <Col md='6' className='pe-md-5'>
                    <blockquote className='ps-3 ps-md-5'>
                        <div className="mb-4">
                            <h3 className='text-primary'>Our vision</h3>
                            <p>Our vision is to become the leading educational consulting firm that helps students and parents achieve their academic dreams through expert guidance and personalized approach.</p>
                        </div>
                        <div className="mb-4">
                            <h3 className='text-primary'>Our Mission</h3>
                            <p>At (name), our mission is to provide personalized educational consulting services that empower students to reach their full potential and achieve their academic goals.</p>
                        </div>
                        <div className="mb-4">
                            <h3 className='text-primary'>Our Services</h3>
                            <p>We offer a variety of educational consulting services, including college admissions counselling, test preparation, academic tutoring, and more. Our goal is to provide comprehensive support to help students achieve their academic and personal goals</p>
                        </div>
                    </blockquote>
                </Col>
                <Col md='6' className='ps-md-5 mt-md-0 mt-4'>
                    <img src={require('../images/about-us.png')} className='w-100' />
                </Col>
            </Row>
        </Container>
    )
}

export default About